<template>
  <nav class="aui-navbar" :class="`aui-navbar--${$store.state.navbarLayoutType}`">
    <div class="aui-navbar__header">
      <h1 class="aui-navbar__brand" @click="$router.push({ name: 'home' })">
        <a class="aui-navbar__brand-lg" href="javascript:;" style="font-size: 15px;">
          <!-- TODO 2024/10/8 东莞路桥临时调整为秦沈高速 -->
          <!-- <img :src="require(`@/assets/img/logo_qsgs_mini.png`)" height="25px"> 中交秦沈高速公路有限公司 -->
          <svg style="height: 40px; width: 100%;"><use xlink:href="#icon-dglq"></use></svg>
        </a>
         <!-- TODO 2024/10/8 东莞路桥临时调整为秦沈高速 -->
         <a class="aui-navbar__brand-mini" href="javascript:;"><img :src="require(`@/assets/img/logo_dglq_mini.jpg`)"></a>
         <!-- <a class="aui-navbar__brand-mini" href="javascript:;"><img :src="require(`@/assets/img/logo_qsgs_mini.png`)"></a> -->
        </h1>
    </div>
    <div class="aui-navbar__body">
      <el-menu class="aui-navbar__menu mr-auto" mode="horizontal">
        <el-menu-item index="1" @click="$store.state.sidebarFold = !$store.state.sidebarFold">
          <svg class="icon-svg aui-navbar__icon-menu aui-navbar__icon-menu--switch" aria-hidden="true"><use xlink:href="#icon-outdent"></use></svg>
        </el-menu-item>
        <el-menu-item index="2" @click="refresh()">
          <svg class="icon-svg aui-navbar__icon-menu aui-navbar__icon-menu--refresh" aria-hidden="true"><use xlink:href="#icon-sync"></use></svg>
        </el-menu-item>
      </el-menu>

      <el-menu class="aui-navbar__menu" mode="horizontal">
        <el-menu-item v-for="item in subsystems" :key="item.id" @click="refreshSidebarMenus(item)">{{ item.name }}</el-menu-item>
      </el-menu>

      <el-menu class="aui-navbar__menu" mode="horizontal">
        <!-- <el-menu-item index="1">
          <el-button v-if="$store.state.user.superAdmin === 1" type="text" @click.native="tenantHandle()">
            <i class="el-icon-house el-icon--right"></i>{{ $store.state.user.tenantName }}
          </el-button>
          <span v-else><i class="el-icon-house el-icon--right"></i>{{ $store.state.user.tenantName }}</span>
        </el-menu-item> -->
        
        <!-- 语言选择下拉框 -->
        <!-- <el-menu-item index="2">
          <el-dropdown placement="bottom" :show-timeout="0">
            <el-button size="mini">{{ $t('_lang') }}</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(val, key) in i18nMessages" :key="key" @click.native="$i18n.locale = key">{{ val._lang }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item> -->
        <!-- 网站链接 -->
        <!-- <el-menu-item index="3">
          <a href="//www.icloud.io/" target="_blank">
            <svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-earth"></use></svg>
          </a>
        </el-menu-item> -->

        <!-- 三维数字孪生平台 -->
        <!-- <el-menu-item index="3" @click="openDts" v-if="$hasPermission('dts:view')">三维数字孪生</el-menu-item> -->
        <el-menu-item index="3" v-if="$hasPermission('dts:view')">
          <router-link title="三维数字孪生" target="_blank" :to="{path: '/dts'}"><svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-earth"></use></svg></router-link>
        </el-menu-item>

        <el-menu-item index="4" @click="openBigScreen()" title="大屏看板">
          <svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-dashboard"></use></svg>
        </el-menu-item>

        <!-- 模型挂接 -->
        <!-- <el-menu-item index="4">
          <router-link title="模型挂接" target="_blank" :to="{path: '/dts-bind'}"><svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-link"></use></svg></router-link>
        </el-menu-item> -->

        <!-- 车载漫游路线绘制 -->
        <!-- <el-menu-item index="5">
          <router-link title="绘制车载路线" target="_blank" :to="{path: '/dts-draw-vehicle-waypoint'}"><svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-car"></use></svg></router-link>
        </el-menu-item> -->

        <el-menu-item index="6" v-if="$hasPermission('sys:notice:all')" @click="myNoticeRouter()">
          <el-badge :is-dot="messageTip">
            <svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-bell-fill"></use></svg>
          </el-badge>
        </el-menu-item>
        
        <!-- 此处可以添加一个“帮助”链接，打开页面中有操作手册下载链接、App二维码等 -->
        <!-- 东莞路桥：隐藏 -->
        <!-- <el-menu-item index="7" @click="myHelpRouter()">
          <svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-question-circle-fill"></use></svg>
        </el-menu-item> -->

        <!-- 东莞路桥：隐藏 -->
        <!-- <el-menu-item index="8" @click="showOnlineUsers()">
          <el-badge :value="onlineUserCount" :max="99">
            <svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-user"></use></svg>
          </el-badge>
        </el-menu-item> -->

        <el-menu-item index="9" @click="fullscreenHandle()">
          <svg class="icon-svg aui-navbar__icon-menu" aria-hidden="true"><use xlink:href="#icon-fullscreen"></use></svg>
        </el-menu-item>
        <el-menu-item index="10" class="aui-navbar__avatar">
          <el-dropdown placement="bottom" :show-timeout="0">
            <span class="el-dropdown-link">
              <!-- 右上角头像 -->
              <!-- 
                <img :src="headUrl">
               -->
              <span>{{ $store.state.user.realName }}</span>
              <i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- 东莞路桥：隐藏 -->
              <!-- <el-dropdown-item @click.native="showOnlineUsers()">在线用户</el-dropdown-item>
              <el-dropdown-item @click.native="updatePasswordHandle()">{{ $t('updatePassword.title') }}</el-dropdown-item> -->
              <el-dropdown-item @click.native="updateUserInfoHandle()">个人信息</el-dropdown-item>
              <el-dropdown-item @click.native="logoutHandle()">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- 弹窗, 修改密码 -->
    <update-password v-if="updatePasswordVisible" ref="updatePassword"></update-password>
    <!-- 弹窗, 个人信息 -->
    <update-userinfo v-if="updateUserinfoVisible" ref="updateUserinfo"></update-userinfo>
    <!-- 弹窗, 切换租户 -->
    <switch-tenant v-if="switchTenantVisible" ref="switchTenant"></switch-tenant>
    <!-- 弹窗, 在线用户 -->
    <online-users v-if="onlineUsersVisible" ref="onlineUsers"></online-users>
  </nav>
</template>

<script>
import { messages } from '@/i18n'
import screenfull from 'screenfull'
import UpdatePassword from './main-navbar-update-password'
import UpdateUserinfo from './main-navbar-update-userinfo'
import SwitchTenant from './main-navbar-switch-tenant'
import { clearLoginInfo } from '@/utils'
import OnlineUsers from './online-users'
import Cookies from 'js-cookie'
import CryptoJS from "crypto-js"
export default {
  inject: ['refresh'],
  data () {
    return {
      i18nMessages: messages,
      updatePasswordVisible: false,
      updateUserinfoVisible: false,
      switchTenantVisible: false,
      messageTip: false,
      subsystems: window.SITE_CONFIG['subsystems'],
      headUrl: '',
      // 在线人数
      onlineUserCount: 1,
      onlineUsersVisible: false,
      onlineUserCountTask: null
    }
  },
  components: {
    UpdatePassword,
    UpdateUserinfo,
    SwitchTenant,
    OnlineUsers
  },
  created () {
    // 未读通知数
    this.getUnReadCount()
    // 加载头像
    this.loadAvatar()
    // 启动在线人数刷新程序
    this.startOnlineUserCountTask()
  },
  watch: {
    
  },
  methods: {
    // 通过全局事件总线触发左侧模块菜单的刷新方法
    refreshSidebarMenus (item) {
      let subsystemId = item.id
      // TODO 秦沈高速demo，待删除
      if(item.name == '智慧工地') {
        window.open('https://plugins.pmbim.com.cn/demo/iot.jsp')
      } else if(item.name == '资产管理') {
        window.open('https://plugins.pmbim.com.cn/demo/am.jsp')
      }
      
      this.$bus.$emit('refreshSidebarMenus', subsystemId)
    },
    // 加载头像
    loadAvatar () {
      if (this.$store.state.user.headUrl) {
        this.$http.get(`/oss/file/signedUrl?url=${this.$store.state.user.headUrl}`)
        .then(({ data: res }) => {
          if (res.code === 0) {
            this.headUrl = res.data
          } else {
            this.headUrl = require('../assets/img/avatar.png')
          }
        }).catch(() => {})
      } else {
        this.headUrl = require('../assets/img/avatar.png')
      }
    },
    // 消息通知
    myNoticeRouter () {
      if (this.$hasPermission('sys:notice:all')) {
        this.$router.replace('sys-notice-user')
      }
    },
    // 未读消息角标
    getUnReadCount () {
      this.$http.get(`/sys/notice/mynotice/unread`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data > 0) {
          this.messageTip = true
        }
      }).catch(() => {})
    },
    // 帮助中心
    myHelpRouter () {
      if (this.$hasPermission('sys:help:center')) {
        this.$router.replace('sys-help-center')
      }
    },
    // 全屏
    fullscreenHandle () {
      if (!screenfull.enabled) {
        return this.$message({
          message: this.$t('fullscreen.prompt'),
          type: 'warning',
          duration: 500
        })
      }
      screenfull.toggle()
    },
    // 切换租户
    tenantHandle () {
      this.switchTenantVisible = true
      this.$nextTick(() => {
        this.$refs.switchTenant.init()
      })
    },
    // 修改密码
    updatePasswordHandle () {
      this.updatePasswordVisible = true
      this.$nextTick(() => {
        this.$refs.updatePassword.init()
      })
    },
    // 个人信息（主要是头像）
    updateUserInfoHandle () {
      this.updateUserinfoVisible = true
      this.$nextTick(() => {
        this.$refs.updateUserinfo.init()
      })
    },
    // 退出登录
    logoutHandle () {
      this.$confirm(this.$t('prompt.info', { 'handle': this.$t('logout') }), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.post('/auth/oauth/logout').then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          clearLoginInfo()
          
          if (Cookies.get('opm_login_source') === 'cas') {
            Cookies.remove('opm_login_source')
            // 东莞路桥：门户
            window.location.href = 'http://luqiao.dgbigdata.com/sso/login'
          } else {
            this.$router.push({ name: 'login' })
          }
        }).catch()
      }).catch()
    },
    // 打开三维数字孪生平台
    openDts() {
      const openRouter = this.$router.resolve({
        path: "/dts",
      })
      window.open(openRouter.href, "_blank")
    },
    // 刷新在线人数定时器
    startOnlineUserCountTask() {
      this.loadOnlineUserCount()
      this.onlineUserCountTask = window.setInterval(()=>{
        this.loadOnlineUserCount()
        // 为了账户安全，当用户已长时间未操作系统，自动退出登录
        this.autoLogoutAfterLongTimeIdle()
      }, 30 * 60 * 1000) // 30分钟
    },
    // 为了账户安全，当用户已长时间未操作系统，自动退出登录
    autoLogoutAfterLongTimeIdle() {
      if ((new Date().getTime() - this.$store.state.lastOperationTime) >= 5 * 60 * 60 * 1000) {// 5小时
        window.clearInterval(this.onlineUserCountTask)
        this.$http.post('/auth/oauth/logout').then(({ data: res }) => {
          clearLoginInfo()
          this.$router.push({ name: 'login' })
        }).catch()
      }
    },
    // 查询在线用户数
    loadOnlineUserCount() {
      this.$http.get('/sys/online/count').then(({ data: res }) => {
        if (res.code == 0) {
          this.onlineUserCount = res.data
        }
      }).catch()
    },
    // 查看在线用户
    showOnlineUsers() {
      this.onlineUsersVisible = true
      this.$nextTick(() => {
        this.$refs.onlineUsers.init()
      })
    },
    openBigScreen() {
      let params = JSON.stringify({
        at: Cookies.get('access_token'),
        rt: Cookies.get('refresh_token')
      })
      let encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(params))
      window.open(`${window.SITE_CONFIG['bigScreenURL']}/#/index?p=${encoded}`, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-menu-item {
    font-weight: bold
  }
</style>
