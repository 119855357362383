<template>
  <aside :class="['aui-sidebar', `aui-sidebar--${$store.state.sidebarLayoutSkin}`]">
    <div class="aui-sidebar__inner">
      <el-menu
        :default-active="$store.state.sidebarMenuActiveName"
        :collapse="$store.state.sidebarFold"
        :unique-opened="true"
        :collapseTransition="false"
        class="aui-sidebar__menu">
        <sub-menu v-for="menu in $store.state.sidebarMenuList" :key="menu.id" :menu="menu" />
      </el-menu>
    </div>
  </aside>
</template>

<script>
import SubMenu from './main-sidebar-sub-menu'
export default {
  data () {
    return {
    }
  },
  components: {
    SubMenu
  },
  created () {
    let subsystemArr = window.SITE_CONFIG['subsystems']
    let activeSubsystem = null
    if (subsystemArr && subsystemArr.length > 0) {
      subsystemArr.forEach(item => {
        if (item.isActive) {
          activeSubsystem = item
        }
      })
      // 如果没有激活的子系统，则默认激活第一个
      if (activeSubsystem == null) {
        activeSubsystem = subsystemArr[0]
      }
    } else {
      activeSubsystem = {
        id: '0',
        name: ' ',
        isActive: true
      }
    }
    this.refreshSidebarMenus(activeSubsystem.id)
  },
  methods: {
    // 根据当前所属的子系统过滤左侧显示的模块和菜单
    refreshSidebarMenus (subsystemId) {
      let menus = window.SITE_CONFIG['menuList']
      let filterMenus = []
      if (menus && menus.length > 0) {
        menus.forEach(item => {
          if (item.subsystemId == subsystemId && item.type == 0) {
            filterMenus.push(item)
          }
        })
      }
      this.$store.state.sidebarMenuList = filterMenus
    }
  }
}
</script>
<style lang="scss" scoped>
// ::v-deep .el-submenu__title {
//   font-weight: bold !important;
// }
</style>