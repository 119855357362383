<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__materiallist">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.materialName" placeholder="材料名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:materiallist:save')" @click="batchAddHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:materiallist:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="materialName" label="材料名称" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="spec" label="规格型号" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="num" label="数量" width="160" align="right" sortable="custom"></el-table-column>
        <el-table-column prop="unit" label="单位" width="160" align="center" sortable="custom"></el-table-column>
        <el-table-column :label="$t('handle')" align="center" width="140">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:materiallist:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:materiallist:delete')" @click="deleteHandle(row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <batch-add v-if="batchAddVisible" ref="batchAdd" @refreshDataList="getDataList" @close="closeBatchAddDialogHandle"></batch-add>
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './materiallist-add-or-update'
import BatchAdd from "./materiallist-batch-add"

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/materialList/page',
        getDataListIsPage: true,
        deleteURL: '/mps/materialList',
      },
      dataForm: {
        contractId: '',
        spec: '',
        materialName: '' // 材料名称
      },
      batchAddVisible: false,
    }
  },
  components: {
    BatchAdd,
    AddOrUpdate
  },
  methods: {
    // 标段切换事件
    contractChange(data) {
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    // 批量新增 材料清单
    batchAddHandle() {
      this.batchAddVisible = true
      this.$nextTick(() => {
        this.$refs.batchAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.batchAdd.init()
      })
    },
    // 关闭批量新增弹窗
    closeBatchAddDialogHandle() {
      this.batchAddVisible = false
    }
  }
}
</script>